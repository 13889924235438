import React from 'react';
import TopBar from './TopBar';
import { useRouter } from 'next/router';
import { useViewerShops } from '../../api';
import ShopAdminBottomBar from '../../components/navigation/ShopAdminBottomBar';

// Icons
import HomeOutlinedIcon from '@/img/home-outlined.svg';
import HomeFilledIcon from '@/img/home-filled.svg';
import CartIcon from '@/img/shopping-cart_[special].svg';
import CartFilledIcon from '@/img/shopping-cart_[special]_filled.svg';
import AllLives from './AllLives';
import { Stack } from '@mui/material';
import { colorTokens } from '../../theme/color-tokens';

const HomePagePostSignIn = () => {
  const { pathname, query } = useRouter();

  const navItems = [
    {
      label: 'Home',
      icon: pathname === '/' ? <HomeFilledIcon /> : <HomeOutlinedIcon />,
      url: "/",
      active: pathname === '/' ? true : false
    },
    {
      label: 'Cart',
      icon: pathname === '/cart' ? <CartFilledIcon /> : <CartIcon />,
      url: "/buyer/carts",
      active: pathname === '/cart' ? true : false
    },
  ];

  return (
    <>
      <TopBar title="Home" />
      <Stack p="16px" style={{ backgroundColor: colorTokens.backgroundScreen }}>
        <AllLives stickyMuteButton/>
      </Stack>
      <ShopAdminBottomBar activeIndex={0} navItems={navItems} shopSlug={query?.shopSlug as string} />
    </>
  );
};

export default HomePagePostSignIn;