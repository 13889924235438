import React, { FC, useMemo } from 'react';
import { Button, createTheme, IconButton, styled, ThemeProvider } from '@mui/material';
import { colors } from '../../../theme/colors';
import { colorTokens } from '../../../theme/color-tokens';
import ProgressSpinner from '../../../components/ProgressSpinner';

interface ContainedIconButtonProps {
    loading?: boolean;
    disabled?: boolean;
    onClick?: (e?: any) => any;
    size?: 'large' | 'medium' | 'small';
    fullWidth?: boolean;
    color?: string;
    bgColor?: string;
    children: React.ReactNode;
 };


const ContainedIconButton: FC<ContainedIconButtonProps> = ({ children, loading, disabled, onClick, size, color, bgColor }) => {
  const theme = useMemo(() => createTheme(), []);
  return (
    <ThemeProvider theme={theme}>
    <StyledContainedIconButton size={size} disabled={disabled} onClick={(e) => {
        e.preventDefault()
        if (!loading) {
            onClick?.(e)
        }
    }} disableElevation disableFocusRipple disableTouchRipple disableRipple style={{
        color: color,
        backgroundColor: bgColor
    }}>
        {loading? <ProgressSpinner size={size === 'medium' || size === 'large' ? "24px" : "16px"} color="#fff"/> : children}
    </StyledContainedIconButton>
    </ThemeProvider>
  )
}

export default ContainedIconButton;

const StyledContainedIconButton = styled(Button)(({ disabled, size }) => ({
    minWidth: 'auto', //Otherwise min-width is 64px
    width: size === 'medium' || size === 'large' ? '56px' : '40px',
    height: size === 'medium' || size === 'large' ? '56px' : '40px',
    boxSizing: 'border-box',
    backgroundColor: colorTokens.buttonBackgroundDefault,
    borderRadius: '8px',
    color: '#fff !important',
    padding: size === 'medium' || size === 'large' ? '16px' : '10px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: 'auto',
    textTransform: 'none',
    opacity: disabled? '0.5' : '1',

    '&:hover': {
        backgroundColor: colorTokens.buttonBackgroundHover
    },

    '&:focus': {
        boxShadow: `0 0 0 3px ${colors.purple100}`, 
    },

    '&:active': {
        backgroundColor: colorTokens.buttonBackgroundActive,
        boxShadow: 'none',
    }
 }))