import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import ProgressSpinner from '../../../components/ProgressSpinner';
import { colors } from '../../../theme/colors';
import BecomeASellerButton from '../../buttons/BecomeASellerButton';
import Settings from '@/img/settings.svg';
import { List } from '@mui/material';
import ListItem from '../../lists/ListItem';
import { storePreviousRouteDetails } from 'store/reducers/app.reducer';
import { useDispatch } from 'react-redux';

const BuyerView = () => {
  const [btnClicked, setBtnClicked] = useState(false);
  const { push } = useRouter();
  const dispatch = useDispatch();

  return (
    <Stack>
      <Stack padding="0 16px 16px 16px">
        <Stack pb="16px">
          <BecomeASellerButton
            onClick={() => {
              setBtnClicked(true);
              push('/shopdetails');
            }}
          >
            {btnClicked ? <ProgressSpinner size="24px" color={colors.white} /> : 'Become a Seller'}
          </BecomeASellerButton>
        </Stack>
      </Stack>
      <Divider />
      <Stack pt="8px">
        <List>
          <ListItem
            size="large"
            startIcon={<Settings />}
            option="Account Settings"
            variant="link"
            onClick={() => {
              dispatch(
                storePreviousRouteDetails({
                  previousRoute: window.location.pathname
                })
              );
              push({
                pathname: `/settings`
              });
            }}
          />
        </List>
      </Stack>
    </Stack>
  );
};

export default BuyerView;
