import React, { FC } from 'react';
import { FlexProps } from '@jatango/design';
import { useTranslation } from 'next-i18next';
import { BottomNavigation, BottomNavigationAction, createTheme, styled, ThemeProvider, Paper, Stack } from '@mui/material';
import { useRouter } from 'next/router';

import ContainedIconButton from '../../componentsV2/buttons/IconButtons/ContainedIconButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { colors } from '../../theme/colors';
import { Url } from 'next/dist/shared/lib/router/router';
import { colorTokens } from '../../theme/color-tokens';

const theme = createTheme({});

export type ShopAdminBottomBarProps = FlexProps & {
  shopSlug: string;
  onAdd?: () => void;
  navItems: any[];
  activeIndex: number;
};

const ShopAdminBottomBar: FC<ShopAdminBottomBarProps> = ({ shopSlug, onAdd, navItems, activeIndex, ...props }) => {
  const { push } = useRouter();

  const { t } = useTranslation('navigation');
  const baseShopPath = `/${shopSlug}`;
  const addFromNav = () => {
    localStorage.setItem('fromNav', 'true');
    if (onAdd) {
      onAdd();
    }
  };

  return (
    <>
      {/* <BottomBar {...props}>
        <BottomBarItem icon={Home} label={t('pages.home')} to={baseShopPath} />
        <BottomBarItem icon={Video} label={t('pages.shows')} to={`${baseShopPath}/shows`} />
        <BottomBarAddButton aria-label={t('actions.add')} onPress={addFromNav} />
        <BottomBarItem icon={Tag} label={t('pages.products')} to={`${baseShopPath}/products`} />
        <BottomBarItem icon={ShoppingBag} label={t('pages.orders')} to={`${baseShopPath}/orders`} />
      </BottomBar> */}
      <ThemeProvider theme={theme}>
        <Navbar elevation={2}>
          <BottomNavigation showLabels value={activeIndex}>
            {navItems.map((navItem, index) =>
              navItem.label === 'Add New' ? (
                <Stack height="100%" width="calc(100vw/5)" justifyContent="center" alignItems="center">
                  <ContainedIconButton size="small" onClick={addFromNav}>
                    <AddRoundedIcon />
                  </ContainedIconButton>
                </Stack>
              ) : (
                <NavigationItem
                  key={index}
                  selected={navItem.active}
                  label={navItem.label}
                  icon={navItem.icon}
                  onClick={() => push(navItem.url as Url)}
                />
              )
            )}
          </BottomNavigation>
        </Navbar>
      </ThemeProvider>
    </>
  );
};

export default ShopAdminBottomBar;

const Navbar = styled(Paper)(() => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 6000
}));

const NavigationItem = styled(BottomNavigationAction)<{ selected?: boolean }>(({ selected }) => ({
  ['span']: {
    fontFamily: 'Inter',
    fontWeight: 'bold'
  },

  ['.MuiBottomNavigationAction-label']: {
    color: selected ? `${colorTokens.textPrimary} !important` : colors.gray700,
    fontSize: '12px !important'
  }
}));
