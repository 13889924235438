import { useRelayMutation } from '@/lib';
import {
  SendVerificationCodeMutation,
  SendVerificationCodeMutationVariables,
  SendVerificationCodeDocument,
  VerifyCodeMutation,
  VerifyCodeMutationVariables,
  VerifyCodeDocument,
} from '@/api';

export function useSendVerificationCode() {
  return useRelayMutation<SendVerificationCodeMutation, SendVerificationCodeMutationVariables, 'sendVerificationCode'>(
    SendVerificationCodeDocument,
    'sendVerificationCode'
  );
}

export function useVerifyCode() {
  return useRelayMutation<VerifyCodeMutation, VerifyCodeMutationVariables, 'verifyCode'>(
    VerifyCodeDocument,
    'verifyCode'
  );
}
