import { configureStore } from '@reduxjs/toolkit';
import AppReducer from './reducers/app.reducer';

export const store = configureStore({
  reducer: {
    app: AppReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
