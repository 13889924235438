import React, { FC, ReactNode } from 'react';
import MUIAvatar, { AvatarProps as MUIAvatarProps } from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

interface AvatarProps extends Omit<MUIAvatarProps, 'size'> {
  alt?: string;
  src?: string;
  defaultIcon?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

// Define size mappings
const sizeMap = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 56,
  xl: 72
};

const StyledAvatar = styled(MUIAvatar)<{ size: AvatarProps['size'] }>(({ size = 'sm' }) => ({
  width: `${sizeMap[size]}px`,
  height: `${sizeMap[size]}px`,
  fontSize: `${sizeMap[size] / 2}px` // Adjust font size proportionally if using initials
}));

const Avatar: FC<AvatarProps> = ({ alt, src, defaultIcon, size }) => {
  return (
    <StyledAvatar alt={alt} src={src} size={size}>
      {!src && defaultIcon}
    </StyledAvatar>
  );
};

export default Avatar;