import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppNavigationState {
  previousRoute: string | null;
  previousTab?: number | null;
}

const initialState: AppNavigationState = {
  previousRoute: null,
  previousTab: 0
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    storePreviousRouteDetails: (state, action: PayloadAction<AppNavigationState>) => {
      return { ...state, ...action.payload };
    }
  }
});

// Export actions
export const { storePreviousRouteDetails } = appSlice.actions;

// Export reducer
export default appSlice.reducer;
