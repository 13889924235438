import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import { Paragraph } from '../../../theme/text-variants';
import { colors } from '../../../theme/colors';
import ChevronRight from '@mui/icons-material/ChevronRight';
import StoreFrontIcon from '@/img/storefront.svg';
import Avatar from '../../Avatar';

interface StoreCardProps {
  shop?: any;
  onClick?: () => void;
}

const StoreCard: FC<StoreCardProps> = ({ shop, onClick }) => {
  return (
    <StoreCardContainer
      key={shop?.id}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="8px 0px"
      onClick={() => onClick?.()}
    >
      <Stack direction="row" alignItems="center" gap="16px">
        <Avatar
          alt={`${shop.name} image`}
          src={
            shop?.shopCustomizations?.length > 0 ? (shop.shopCustomizations as any)?.[0].shopProfileImage?.originalSrc : ''
          }
          defaultIcon={<StoreFrontIcon />}
        />
        <Stack>
          <Paragraph size="md" fontWeight={"bold"}>{shop?.name}</Paragraph>
          <Paragraph size="sm" color={colors.gray700}>
            Owner
          </Paragraph>
        </Stack>
      </Stack>
      <ChevronRight />
    </StoreCardContainer>
  );
};

export default StoreCard;

const StoreCardContainer = styled(Stack)(() => ({
  ['& :hover']: {
    cursor: 'pointer'
  }
}));
