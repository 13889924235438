import { Box, Stack, styled } from '@mui/material';
import { colors } from '@/theme/colors';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import CustomCard from '@/components/cards/Card';
import { Heading } from '@/theme/text-variants';
import { createTheme, ThemeProvider } from '@mui/material';
import { initializeAgoraRTCClient, joinAsAudience, RTC } from '@/lib/live';
import { useRouter } from 'next/router';
import Tag from '@/componentsV2/Tag';
import UserTag from '@/componentsV2/UserTag';
import { Show, useShowBySlugPublic } from '@/api';
import { colorTokens } from '../../theme/color-tokens';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import VolumeOffRoundedIcon from '@mui/icons-material/VolumeOffRounded';
import JatangoShowBg from '@/img/jatango-show.png';

const theme = createTheme();

interface GlobalChannelsListProps {
    token: string;
    uid: number;
    jatangoChannel?: Channel | null;
    liveChannels: Channel[];
    pastChannels: { shopSlug: string; showSlug: string; src: string, blobName?: string }[];
    stickyMuteButton?: boolean;
    // setPage?: Dispatch<SetStateAction<number>>;
}

type Channel = ({
    channel_name: string;
    user_count?: number;
});

const LiveShowCard = ({ show, channelName }: { show: Show; channelName: string }) => (
    <CustomCard contentPadding="0" margin="0" style={{ backgroundColor: 'transparent' }}>
        <Stack style={{ width: '100%', height: '256px', position: 'relative', backgroundColor: 'transparent' }} id={channelName}>
            <Stack p="8px" position="absolute" left="0" top="0" zIndex={6000} justifyContent="space-between" width="calc(100% - 16px)" height="calc(100% - 16px)">
                <Stack direction="row" justifyContent="space-between">
                    <Tag variant="alert" size="xs" label="Live" dark />
                    {show?.affiliateShopId ? <UserTag label={show?.affiliateShop?.name} size="sm" /> : <UserTag label={show?.shop?.name} size="sm" />}
                </Stack>
                <Heading size="md" shadow color={colors.white}>{show?.name}</Heading>
            </Stack>
        </Stack>
    </CustomCard>
);

const GlobalChannelsList: FC<GlobalChannelsListProps> = ({ token, uid, liveChannels, pastChannels, jatangoChannel, stickyMuteButton = false }) => {
    const { query, push } = useRouter();

    // Jatango Show
    const jatangoShopSlug = (jatangoChannel as Channel)?.channel_name.split("_")[0];
    const jatangoShowSlug = (jatangoChannel as Channel)?.channel_name.split("_")[1];
    const { show: jatangoShow } = useShowBySlugPublic(jatangoShopSlug, jatangoShowSlug);
    const jatangoShowImageUrl = jatangoShow?.showImages?.[0]?.image?.transformedSrc;

    const [hoveredChannel, setHoveredChannel] = useState<string | null>(null);
    const [remoteUser, setRemoteUser] = useState<IAgoraRTCRemoteUser>();
    const [muteMainVideo, setMuteMainVideo] = useState<boolean>(false);
    const [videosUnmuted, setVideosUnmuted] = useState(false);
    // const [jtVideoUrl, setJtVideoUrl] = useState<string | null>(null);
    const [audioAutoplayFailed, setAudioAutoplayFailed] = useState(false);
    const [options, setOptions] = useState({
        appId: process.env.NEXT_PUBLIC_AGORA_APP_ID as string,
        channel: null,
        token,
        uid,
    });

    const rtc = useRef<RTC>({
        localAudioTrack: null,
        localVideoTrack: null,
        client: null,
        compositor: null,
    }).current;

    useEffect(() => {
        if (options.token && options.uid) {
            initializeAgoraRTCClient(rtc, false);
        }
    }, [options.token, options.uid]);

    const displayRemoteVideo = (user: any, elementId: string) => {
        const remotePlayerContainer = document.getElementById(elementId) as HTMLElement;
        user?.videoTrack?.play(remotePlayerContainer);
        user?.audioTrack?.play(remotePlayerContainer)
    };

    const handleRemoteUsers = (videoPlayerContainerId?: string, onRemoteUserJoined?: (user: IAgoraRTCRemoteUser) => void, onAudioAutoPlayFailure?: () => void) => {

        rtc.client?.on('user-published', async (user, mediaType) => {
            await rtc.client?.subscribe(user, mediaType);
            console.log('subscribe success')

            if (mediaType === 'video') {
                displayRemoteVideo(user, videoPlayerContainerId ?? hoveredChannel!);
            }

            if (mediaType === 'audio') {
                // user?.audioTrack?.play()

                if (!user?.audioTrack?.isPlaying) {
                    onAudioAutoPlayFailure?.();
                }
            }

            onRemoteUserJoined?.(user)
        });

        rtc.client?.on('user-unpublished', user => {
            const remotePlayerContainer = document.getElementById(videoPlayerContainerId ?? hoveredChannel!) as HTMLElement;
            user?.videoTrack?.stop()
            user?.audioTrack?.stop()
        });
    };

    useEffect(() => {
        if (jatangoShow && jatangoChannel) {
            joinAsAudience(rtc, { ...options, channel: jatangoChannel.channel_name }).then(() =>
                handleRemoteUsers(jatangoChannel.channel_name, (user) => {
                    setHoveredChannel(null);
                    // user?.audioTrack?.play();
                    setRemoteUser(user);
                }, () => {
                    setAudioAutoplayFailed(true)
                })
            );
        }
    }, [jatangoShow])

    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Stack pb="16px" onClick={(e) => {
                    // Check if the event was triggered by a touch
                    if (e.detail === 0) return; // `e.detail` is 0 for touch events, greater than 0 for mouse clicks
                    // console.log('Card clicked:', channel_name);
                    window.location.assign(`${window.location.origin}/lives/recorded?bn=dean_ad_2020.mp4`);
                }}>
                    <CustomCard contentPadding="0px" margin="0px" >
                        <Stack style={{ height: '192px', position: 'relative' }}
                            id={jatangoChannel?.channel_name}

                        >
                            <Stack p="8px" position="absolute" left="0" top="0" zIndex={6000} justifyContent="space-between" width="calc(100% - 16px)" height="calc(100% - 16px)">
                                <Box>
                                    <Tag variant="alert" size="xs" label="Live" dark />
                                </Box>
                                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                                    <Heading size="md" shadow color={colors.white}>{jatangoShow?.name}</Heading>
                                </Stack>
                            </Stack>
                            <video
                                src="https://agorajtcloudstoragedev.blob.core.windows.net/agoralivevideos/dean_ad_2020.mp4?sp=r&st=2025-01-24T11:53:03Z&se=2025-02-02T19:53:03Z&spr=https&sv=2022-11-02&sr=b&sig=A2TXCEsMntngXdg9jkhpLwALUen6XevegG4QlmEztu4%3D"
                                autoPlay
                                playsInline
                                loop
                                poster={JatangoShowBg.src}
                                muted={!videosUnmuted || muteMainVideo}
                                onTouchStart={() => {
                                    setMuteMainVideo(false)
                                }}
                                onMouseEnter={() => {
                                    setMuteMainVideo(false)
                                }}
                            />
                        </Stack>
                    </CustomCard>
                </Stack>

                <Stack width="100%" direction="row" gap="16px" flexWrap="wrap">
                    {liveChannels.map(({ channel_name }) => {
                        const [shopSlug, showSlug] = channel_name.split('_');
                        const { show } = useShowBySlugPublic(shopSlug, showSlug);

                        const imageUrl = show?.showImages?.[0]?.image?.transformedSrc;
                        return (
                            <Stack
                                width="calc(50% - 8px)"
                                height="256px"
                                key={channel_name}
                                style={{ userSelect: 'none', backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' }}
                                onTouchStart={() => {
                                    setMuteMainVideo(true)
                                    setMuteMainVideo(true)
                                    joinAsAudience(rtc, { ...options, channel: channel_name }).then(() =>
                                        handleRemoteUsers(channel_name, (user) => setRemoteUser(user), () => { setAudioAutoplayFailed(true) })
                                    );
                                }}
                                onTouchEnd={async () => {
                                    setHoveredChannel(null);
                                    await rtc?.client?.leave();
                                }}
                                onMouseEnter={() => {
                                    setMuteMainVideo(true)
                                    setMuteMainVideo(true)
                                    setHoveredChannel(channel_name);
                                    joinAsAudience(rtc, { ...options, channel: channel_name }).then(() =>
                                        handleRemoteUsers(channel_name, (user) => setRemoteUser(user), () => { setAudioAutoplayFailed(true) })
                                    );
                                }}
                                onMouseLeave={async () => {
                                    setHoveredChannel(null);
                                    await rtc?.client?.leave();
                                }}
                                onClick={(e) => {
                                    // Check if the event was triggered by a touch
                                    if (e.detail === 0) return; // `e.detail` is 0 for touch events, greater than 0 for mouse clicks
                                    // console.log('Card clicked:', channel_name);
                                    window.location.assign(`${window.location.origin}/live/${shopSlug}/${showSlug}/v3`);
                                }}
                            >
                                <LiveShowCard show={show as Show} channelName={channel_name} />
                            </Stack>

                        );
                    })}

                    {/* <PastLiveVideoContainer width="calc(50% - 8px)">
                        <CustomCard margin="0px" height="256px" contentPadding="0px" style={{ backgroundColor: "#000" }} >
                            <Stack style={{ width: '100%', height: '256px', position: 'absolute', backgroundColor: "transparent", pointerEvents: "none" }} >
                                <Stack p="8px" position="absolute" left="0" top="0" zIndex={6000} justifyContent="space-between" width="calc(100% - 16px)" height="calc(100% - 16px)">
                                    <Stack direction="row" justifyContent="space-between">
                                        <Tag variant="neutral" size="xs" label="Ended" dark />
                                        <UserTag label="Jatango" size="sm" />
                                    </Stack>
                                    <Heading size="md" shadow color={colors.white}>Jatango Promo</Heading>
                                </Stack>
                            </Stack>
                            <video
                                loop
                                playsInline
                                style={{ width: '100%', height: '256px' }}
                                src="https://agorajtcloudstoragedev.blob.core.windows.net/agoralivevideos/jatango_rough_cut.mp4?sp=r&st=2025-01-24T01:28:20Z&se=2025-02-01T09:28:20Z&spr=https&sv=2022-11-02&sr=b&sig=CN5Ggv52aIdMNZ0zguU9fa2y8KIVzw%2B2d1F7l6Lpb98%3D"
                                muted={!videosUnmuted}
                                onTouchStart={e => {
                                    setMuteMainVideo(true)
                                    e.currentTarget.play()
                                }}
                                onTouchEnd={e => {
                                    e.currentTarget.pause();
                                    e.currentTarget.currentTime = 0;
                                    e.currentTarget.load(); // Reload to display the poster
                                }}
                                onMouseEnter={e => {
                                    setMuteMainVideo(true)
                                    e.currentTarget.play()
                                }}
                                onMouseLeave={e => {
                                    e.currentTarget.pause();
                                    e.currentTarget.currentTime = 0;
                                    e.currentTarget.load(); // Reload to display the poster
                                }}
                                onClick={(e) => {
                                    // Check if the event was triggered by a touch
                                    if (e.detail === 0) return; // `e.detail` is 0 for touch events, greater than 0 for mouse clicks
                                    // console.log('Card clicked:', channel_name);
                                    window.location.assign(`${window.location.origin}/lives/recorded?bn=jatango_rough_cut.mp4`);
                                }}
                            />
                        </CustomCard>
                    </PastLiveVideoContainer> */}
                    {pastChannels && pastChannels?.map((pastChannel, index) => {
                        const { shopSlug, showSlug, blobName } = pastChannel;
                        const { show } = useShowBySlugPublic(shopSlug, showSlug)

                        const imageUrl = show?.showImages?.[0]?.image?.transformedSrc;

                        if (!show) return <></>

                        return (
                            <PastLiveVideoContainer key={index} width="calc(50% - 8px)">
                                <CustomCard margin="0px" height="256px" contentPadding="0px" >
                                    <Stack style={{ width: '100%', height: '256px', position: 'absolute', backgroundColor: "transparent", pointerEvents: "none" }} >
                                        <Stack p="8px" position="absolute" left="0" top="0" zIndex={6000} justifyContent="space-between" width="calc(100% - 16px)" height="calc(100% - 16px)">
                                            <Stack direction="row" justifyContent="space-between">
                                                <Tag variant="neutral" size="xs" label="Ended" dark />
                                                {show?.affiliateShopId ? <UserTag label={show?.affiliateShop?.name} size="sm" /> : <UserTag label={show?.shop?.name} size="sm" />}
                                            </Stack>
                                            <Heading size="md" shadow color={colors.white}>{show?.name}</Heading>
                                        </Stack>
                                    </Stack>
                                    <video
                                        playsInline
                                        style={{ width: '100%', height: '100%', }}
                                        src={pastChannel.src}
                                        onTouchStart={e => e.currentTarget.play()}
                                        onTouchEnd={e => {
                                            e.currentTarget.pause();
                                            e.currentTarget.currentTime = 0;
                                            e.currentTarget.load(); // Reload to display the poster
                                        }}
                                        onMouseEnter={e => e.currentTarget.play()}
                                        onMouseLeave={e => {
                                            e.currentTarget.pause();
                                            e.currentTarget.currentTime = 0;
                                            e.currentTarget.load(); // Reload to display the poster
                                        }}
                                        onClick={(e) => {
                                            // Check if the event was triggered by a touch
                                            if (e.detail === 0) return; // `e.detail` is 0 for touch events, greater than 0 for mouse clicks
                                            // console.log('Card clicked:', channel_name);
                                            window.location.assign(`${window.location.origin}/lives/recorded?bn=${blobName}&ss=${shopSlug}`);
                                        }}
                                        poster={imageUrl as string}
                                        muted={!videosUnmuted}
                                    />

                                </CustomCard>
                            </PastLiveVideoContainer>
                        );
                    })}
                </Stack>
                {
                    !videosUnmuted && (
                        <Stack width="100%" position={stickyMuteButton ? "sticky" : "fixed"} zIndex={6000} bottom="72px" right="16px" alignItems="flex-end">
                            <Stack width="fit-content">
                                <SoundButton
                                    onClick={() => {
                                        setVideosUnmuted(true);
                                        remoteUser?.audioTrack?.play();
                                        setAudioAutoplayFailed(false);
                                    }}
                                >
                                    <Stack alignItems="center">
                                        <VolumeOffRoundedIcon />
                                        Sound
                                    </Stack>
                                </SoundButton>
                            </Stack>
                        </Stack>
                    )
                }
            </Container>
        </ThemeProvider>
    );
};

export default GlobalChannelsList;

const Container = styled(Stack)({
    // maxWidth: "530px",
    width: "100%",
    margin: 'auto',
    // padding: '16px',
    // backgroundColor: colorTokens.backgroundScreen,
    position: 'relative',
    webkitUserSelect: 'none',
    mozuserSelect: 'none',
    msUserSelectelect: 'none',
    userSelect: 'none',
});

const PastLiveVideoContainer = styled(Stack)({
    position: 'relative',
    webkitUserSelect: 'none',
    mozuserSelect: 'none',
    msUserSelectelect: 'none',
    userSelect: 'none',
});

const SoundButton = styled('button')(() => ({
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: 'none',
    borderRadius: '8px',
    height: 'auto',
    padding: '8px',
    width: 'auto',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: '14px',
    color: colorTokens.iconAlert,

    '& svg': {
        width: '24px',
        height: '24px'
    }
}));
