import Stack from '@mui/material/Stack';
import React, { FC } from 'react';
import StoreCard from './StoreCard';
import { useRouter } from 'next/router';

interface StoresListProps {
  shops: any;
}

const StoresList: FC<StoresListProps> = ({ shops }) => {
  const { push } = useRouter();

  return (
    <Stack mb="64px">
      {shops?.map((shop: any) => (
        <StoreCard key={shop?.id} shop={shop} onClick={() => push(`/${shop?.slug}`)} />
      ))}
    </Stack>
  );
};

export default StoresList;
