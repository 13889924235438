import React, { FC } from 'react';
import Logo from '../../img/logo.svg';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import OutlineButton from '../../componentsV2/buttons/OutlineButton';
import ContainedButton from '../../componentsV2/buttons/ContainedButton';
import { useRouter } from 'next/router';
import RoutingLoaderLayout from '@/layouts/RoutingLoaderLayout';

interface HomeNavbarProps {
  isLoggedIn: boolean;
  login: (returnTo?: string) => void;
  signup: (email?: string | null, returnTo?: string) => void;
}

const HomeNavbar: FC<HomeNavbarProps> = ({ isLoggedIn, login, signup }) => {
  const { push } = useRouter();

  return (
    <RoutingLoaderLayout>
      <Box position="sticky" top="0" zIndex={8000} bgcolor="white">
        <Box
          maxWidth={'1440px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          flex={'1'}
          margin={'0 auto'}
        >
          <Content display={'flex'} alignItems="center">
            <LogoImage>
              <Logo />
            </LogoImage>
            {isLoggedIn ? (
              <OutlineButton size="small" onClick={() => push('/account')}>
                My Account
              </OutlineButton>
            ) : (
              <ButtonGroup display={'flex'}>
                <OutlineButton
                  size="small"
                  onClick={() => signup(null, `${window.location.origin}/signup/validate-phone`)}
                >
                  Sign Up
                </OutlineButton>
                <ContainedButton size="small" onClick={() => login(`/`)}>
                  Log In
                </ContainedButton>
              </ButtonGroup>
            )}
          </Content>
        </Box>
      </Box>
    </RoutingLoaderLayout>
  );
};

export default HomeNavbar;

const Content = styled(Box)`
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 23px;
  padding-bottom: 23px;
  justify-content: space-between;
  @media (max-width: 820px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const LogoImage = styled(Logo)`
  width: 173px;
  height: 40px;
  @media (max-width: 820px) {
    width: 128px;
    height: 32px;
    padding-top: 8px;
    margin-right: 22px;
  }
`;

const ButtonGroup = styled(Box)`
  gap: 12px;
  @media (max-width: 820px) {
    gap: 8px;
  }
`;
