import React, { FC } from 'react';
import { Button, ButtonProps, styled } from '@mui/material';
import { colors } from '../../theme/colors';
import { colorTokens } from '../../theme/color-tokens';
import ProgressSpinner from '../../components/ProgressSpinner';

interface ContainedButtonProps extends ButtonProps {
  id?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  size?: 'large' | 'medium' | 'small';
  fullWidth?: boolean;
  bgColor?: string;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  padding?: string;
  BorderRadius?: string;
}

const ContainedButton: FC<ContainedButtonProps> = (
  { id, children, loading, disabled, onClick, size, fullWidth, bgColor, type, padding, BorderRadius, startIcon },
  ...props
) => {
  return (
    <StyledContainedButton
      startIcon={startIcon}
      id={id}
      fullWidth={fullWidth}
      size={size}
      type={type}
      disabled={disabled}
      onClick={(e) => {
        if (type !== 'submit') {
          e.preventDefault();
        }
        if (!loading) {
          onClick?.(e);
        }
      }}
      BorderRadius={BorderRadius}
      disableElevation
      disableFocusRipple
      disableTouchRipple
      disableRipple
      style={{
        backgroundColor: bgColor,
        padding: padding
      }}
      {...props}
    >
      {loading ? (
        <ProgressSpinner size={size === 'medium' || size === 'large' ? '24px' : '16px'} color="#fff" />
      ) : (
        children
      )}
    </StyledContainedButton>
  );
};

export default ContainedButton;

const StyledContainedButton = styled(Button)<ContainedButtonProps>(({ disabled, size, BorderRadius }) => ({
  boxSizing: 'border-box',
  backgroundColor: `${colorTokens.buttonBackgroundDefault} !important`,
  borderRadius: '16px',
  padding: '0 16px',
  minWidth: '200px',
  height: '56px',
  color: '#fff !important',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: 'auto',
  textTransform: 'none',

  ...(size === 'medium' && {
    height: '40px',
    minWidth: '100px',
    borderRadius: '8px',
    fontSize: '14px'
  }),

  ...(size === 'small' && {
    padding: '0 12px',
    minWidth: 'fit-content',
    height: '32px',
    borderRadius: '8px',
    fontSize: '12px'
  }),

  '&:hover': {
    backgroundColor: colorTokens.buttonBackgroundHover
  },

  '&:focus': {
    boxShadow: `0 0 0 3px ${colors.purple100}`
  },
  '&:disabled': {
    backgroundColor: colorTokens.buttonBackgroundDefault,
    opacity: '0.5'
  },
  '&:active': {
    backgroundColor: colorTokens.buttonBackgroundActive,
    boxShadow: 'none'
  }
}));
