import { ServerSideQuery } from '@/lib';
import { ViewerDocument, ViewerShopsDocument, UsersByEmailDocument } from '@/api';

export const viewerQuery: ServerSideQuery = (client, _) =>
  client.query({
    query: ViewerDocument
  });

export const viewerShopsQuery: ServerSideQuery = (client, _) =>
  client.query({
    query: ViewerShopsDocument
  });

