import React, { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { colorTokens } from '../theme/color-tokens';
import { Paragraph, textVariant } from '../theme/text-variants';
import { Stack } from '@mui/material';
import { colors } from '../theme/colors';

export interface TagProps {
  dark?: boolean;
  label?: string;
  icon?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'default' | 'success' | 'alert' | 'neutral' | 'accent' | 'blue' | 'yellow';
  onClick?: (e?: any) => void;
}

const mapFontSize = {
  "xs": "2xs",
  "sm": "xs",
  "md": "sm",
  "lg": "md"
};

const Tag: FC<TagProps> = ({ children, size = 'xs', label, icon, variant = "default", ...props }) => {
  return (
    <StyledTag size={size} variant={variant} {...props}>
      <Stack direction="row" gap="4px" alignItems="center">
        {icon}
        <Paragraph size={mapFontSize[size] as textVariant} color="inherit" fontWeight="bold">
          {label}
        </Paragraph>
      </Stack>
    </StyledTag>
  );
};

export default Tag;

const StyledTag = styled(Box)<TagProps>(({ size, variant, dark }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '4px',
  borderRadius: '4px',
  height: '15px',
  padding: '2px 6px',
  color: colorTokens.textPrimary,
  backgroundColor: colorTokens.itemHover,

  ['& *']: {
    boxSizing: 'border-box'
  },

  //   Color styles
  ...(variant === 'success' && {
    backgroundColor: dark ? colorTokens.backgroundSuccess : colors.green100,
    color: dark ? `${colors.white} !important` : `${colorTokens.textSuccess} !important`
  }),

  ...(variant === 'alert' && {
    backgroundColor: dark ? colorTokens.iconAlert : colors.red100,
    color: dark ? `${colors.white} !important` : `${colorTokens.textAlert} !important`
  }),

  ...(variant === 'neutral' && {
    backgroundColor: dark ? colorTokens.iconDefault : colorTokens.backgroundChip,
    color: dark ? `${colors.white} !important` :  `${colorTokens.textSubtle} !important`
  }),

  ...(variant === 'accent' && {
    backgroundColor: dark ? colorTokens.iconAccent : colors.orange100,
    color: dark ? `${colors.white} !important` : `${colorTokens.textAlert} !important`
  }),

  ...(variant === 'blue' && {
    backgroundColor: dark ? colorTokens.iconBlue : colors.blue100,
    color: dark ? `${colors.white} !important` : `${colorTokens.textLink} !important`
  }),

  ...(variant === 'yellow' && {
    backgroundColor: dark ? colorTokens.textWarning : colors.yellow100,
    color: dark ? `${colors.white} !important` : `${colorTokens.textWarning} !important`
  }),

  //   Size styles
  ...(size === 'sm' && {
    height: '18px',
    padding: '3px 6px',
    borderRadius: '6px'
  }),

  ...(size === 'md' && {
    height: '21px',
    padding: '4px 8px',
    borderRadius: '8px'
  }),

  ...(size === 'lg' && {
    height: '24px',
    padding: '4px 8px',
    borderRadius: '8px'
  })
}));
