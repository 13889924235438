import React from 'react';
import HomeNavbar from '../../components/HomePage/HomeNavbar';
import { useAuth } from '../../lib';
import AllLives from './AllLives';
import Stack from '@mui/material/Stack';
import { colorTokens } from '../../theme/color-tokens';

const HomePagePreSignIn = () => {
  const {login, signup } = useAuth();

  return (
    <>
      <HomeNavbar isLoggedIn={false} login={login} signup={signup} />
      <Stack p="16px" style={{ backgroundColor: colorTokens.backgroundScreen }}>
        <AllLives stickyMuteButton/>
      </Stack>
    </>
  );
};


export default HomePagePreSignIn;





