import { colors } from './colors';

export const colorTokens = {
  // Text
  textDefault: colors.black,
  textSubtle: colors.gray800,
  textPlaceholder: colors.gray500,
  textSuccess: colors.green800,
  textAlert: colors.red600,
  textWarning: colors.yellow700,
  textPrimary: colors.purple500,
  textAccent: colors.orange600,
  textLink: colors.blue600,
  textOnImage: colors.white,

  // Icon
  iconDefault: colors.gray800,
  iconSubtle: colors.gray500,
  iconHeavy: colors.black,
  iconAlert: colors.red600,
  iconSuccess: colors.green600,
  iconPrimary: colors.purple500,
  iconAccent: colors.orange500,
  iconBlue: colors.blue600,

  // Background
  backgroundPrimary: colors.purple500,
  backgroundDefault: colors.white,
  backgroundScreen: colors.gray100,
  backgroundDisabled: colors.gray50,
  backgroundSelected: colors.purple50,
  backgroundChip: colors.gray50,
  backgroundOverlay: 'rgba(0,0,0,0.25)',
  backgroundSuccess: colors.green700,
  backgroundHover: colors.gray50,
  backgroundAlert: colors.red600,
  backgroundFocus: 'rgba(236, 238, 248, 1)',
  backgroundActive: 'rgba(212, 215, 232, 1)',
  backgroundWarning: colors.yellow500,
  backgroundInfo: colors.gray300,
  backgroundOpaque: 'rgba(255, 255, 255, 0.75)',

  // Item
  itemHover: colors.purple100,
  itemActive: colors.purple200,
  itemSubtleHover: colors.gray50,

  // Border
  borderDefault: colors.gray300,
  borderSubtle: colors.gray100,
  borderSelected: colors.purple500,
  borderAlert: colors.red600,
  borderDark: colors.gray500,

  // Button Text
  buttonTextDefault: colors.white,
  buttonTextSubtle: colors.gray300,
  buttonTextPlaceholder: colors.gray500,
  buttonTextSuccess: colors.green800,
  buttonTextAlert: colors.red600,
  buttonTextPrimary: colors.purple500,
  buttonTextAccent: colors.orange600,
  buttonTextLink: colors.blue600,
  buttonOnImage: colors.white,

  // Button Icon
  buttonIconDefault: colors.white,
  buttonIconSubtle: colors.gray300,
  buttonIconHeavy: colors.white,
  buttonIconAlert: colors.red600,
  buttonIconSuccess: colors.red700,
  buttonIconPrimary: colors.purple500,
  buttonIconAccent: colors.orange500,

  // Button Background
  buttonBackgroundDefault: colors.purple500,
  buttonBackgroundScreen: colors.black,
  buttonBackgroundDisabled: colors.gray50,
  buttonBackgroundSelected: colors.purple50,
  buttonBackgroundChip: colors.gray800,
  buttonBackgroundOverlay: 'rgba(0,0,0,0.25)',
  buttonBackgroundSuccess: colors.green700,
  buttonBackgroundAlert: colors.green600,
  buttonBackgroundWarning: colors.yellow500,
  buttonBackgroundInfo: colors.gray300,
  buttonBackgroundHover: colors.purple600,
  buttonBackgroundActive: colors.purple800,
  buttonBackgroundFacebook: '#1877F2',
  buttonBackgroundFacebookHover: '#0C3C79',

  ghostButtonBackgroundHover: '#f4f5fb',
  ghostButtonBackgroundActive: '#D4D7E8',

  // Button Item
  buttonItemHover: colors.purple600,
  buttonItemActive: colors.purple800,
  buttonSubtleHover: colors.gray50,

  // Button Border
  buttonBorderDefault: 'rgba(0,0,0,0)',
  buttonBorderSubtle: 'rgba(0,0,0,0)',
  buttonBorderSelected: 'rgba(0,0,0,0)',
  buttonBorderAlert: colors.red600,

  ghostButtonBorderFocused: '#e1e3f5'
};
