import {
  AddCartItemCustomizationDocument,
  AddCartItemCustomizationMutation,
  AddCartItemCustomizationMutationVariables,
  AddVariantToCartDocument,
  AddVariantToCartMutation,
  AddVariantToCartMutationVariables,
  AddVariantToCartPosDocument,
  AddVariantToCartPosMutation,
  AddVariantToCartPosMutationVariables,
  CartItem,
  CartItemCustomizationFragment,
  CartItemFragment,
  CartItemFragmentDoc,
  RemoveItemFromCartDocument,
  RemoveItemFromCartMutation,
  RemoveItemFromCartMutationVariables,
  RemoveItemFromCartPosDocument,
  RemoveItemFromCartPosMutation,
  RemoveItemFromCartPosMutationVariables,
  UpdateCartItemPosDocument,
  UpdateCartItemPosMutation,
  UpdateCartItemPosMutationVariables,
  toGidString,
  UpdateCartItemCustomizationDocument,
  UpdateCartItemCustomizationMutation,
  UpdateCartItemCustomizationMutationVariables,
  UpdateCartItemDocument,
  UpdateCartItemMutation,
  UpdateCartItemMutationVariables,
  useCartItemUpdatedByCartSubscription,
  useCartQuery,
  useInventoryItemsQuantitySubscription,
  useItemAddedToCartByUserAndShopSubscription,
  useItemRemovedFromCartSubscription,
  useProductsQuantitySubscription,
  useViewerCartItemsByShopSlugQuery,
  VariantDetailsFragment,
  VariantDetailsFragmentDoc,
  ViewerCartItemsByShopSlugDocument,
  ViewerCartItemsByShopSlugQuery,
  ViewerCartItemsByShopSlugQueryVariables,
  useCartsByCustomerQuery
} from '@/api';
import { useRelayMutation } from '@/lib';
import { useApolloClient } from '@apollo/client';
import { addHours, compareAsc } from 'date-fns';
import { v4 as uuid } from 'uuid';
import { useCallback } from 'react';

export function useViewerCartItemsByShopSlug(shopSlug: string, skip?: boolean, subscribeToInventory?: boolean) {
  const {
    data,
    refetch: refetchFn,
    ...rest
  } = useViewerCartItemsByShopSlugQuery({
    variables: {
      shopSlug
    },
    skip
  });

  const refetch = useCallback(async () => {
    const { data } = await refetchFn();

    return {
      cartItems: data?.viewerCartItemsByShopSlug?.nodes ?? []
    };
  }, [refetchFn]);

  const cartItems = data?.viewerCartItemsByShopSlug?.nodes ?? [];
  const inventoryItemIds = cartItems.map((c) => c.variant.inventoryItem!.id);
  const productIds = cartItems.map((c) => c.variant.productId);

  // Subscribe to the product and inventory item quantities
  useProductsQuantitySubscription(productIds, !subscribeToInventory);
  useInventoryItemsQuantitySubscription(inventoryItemIds, !subscribeToInventory);

  return {
    cartItems,
    refetch,
    ...rest
  };
}

export function useCartItemsById(cartId: string) {
  const {
    data,
    refetch: refetchFn,
    ...rest
  } = useCartQuery({
    variables: {
      cartId
    }
  });

  const refetch = useCallback(async () => {
    const { data } = await refetchFn();

    return {
      cartItems: data?.cart?.cartItems?.nodes ?? []
    };
  }, [refetchFn]);
  const cartItems = data?.cart?.cartItems?.nodes ?? [];
  //const inventoryItemIds = cartItems.map((c) => c.variant.inventoryItem!.id);
  //const productIds = cartItems.map((c) => c.variant.productId);
  return {
    cartItems,
    refetch,
    ...rest
  };
}

export function useCartsByCustomer(customerId: string) {
  const {
    data,
    refetch: refetchFn,
    ...rest
  } = useCartsByCustomerQuery({
    variables: {
      customerId
    }
  });

  const refetch = useCallback(async () => {
    const { data } = await refetchFn();

    return {
      cartItems: data?.cartsByCustomer?.nodes ?? []
    };
  }, [refetchFn]);
  const cartItems = data?.cartsByCustomer?.nodes ?? [];

  return {
    cartItems,
    refetch,
    ...rest
  };
}

export function useCartItemsSubscriptions(
  shopSlug: string,
  shopId: string,
  userId: string,
  cartId?: string,
  skip?: boolean
) {
  useItemAddedToCartByUserAndShopSubscription({
    variables: {
      shopId,
      userId
    },
    skip,
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (subscriptionData.data?.itemAddedToCartByUserAndShop) {
        // Read the existing query data
        const viewerCartItemsQuery = client.readQuery<
          ViewerCartItemsByShopSlugQuery,
          ViewerCartItemsByShopSlugQueryVariables
        >({
          query: ViewerCartItemsByShopSlugDocument,
          variables: {
            shopSlug
          }
        });
        if (
          viewerCartItemsQuery?.viewerCartItemsByShopSlug?.nodes?.find(
            (ci) => ci.id === subscriptionData.data?.itemAddedToCartByUserAndShop.id
          )
        ) {
          // The item is already in the cart
          return;
        }
        if (viewerCartItemsQuery) {
          // Write the updated query
          client.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug
            },
            data: {
              ...viewerCartItemsQuery,
              viewerCartItemsByShopSlug: {
                ...viewerCartItemsQuery.viewerCartItemsByShopSlug!,
                nodes: [
                  ...(viewerCartItemsQuery.viewerCartItemsByShopSlug!.nodes?.filter(
                    (ci) => !ci.id.includes('optimistic')
                  ) ?? []),
                  subscriptionData.data.itemAddedToCartByUserAndShop
                ]
              }
            }
          });
        }
      }
    }
  });
  useItemRemovedFromCartSubscription({
    variables: {
      cartId: cartId!
    },
    skip: !cartId || skip,
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (subscriptionData.data?.itemRemovedFromCart) {
        // Read the existing query data
        const viewerCartItemsQuery = client.readQuery<
          ViewerCartItemsByShopSlugQuery,
          ViewerCartItemsByShopSlugQueryVariables
        >({
          query: ViewerCartItemsByShopSlugDocument,
          variables: {
            shopSlug
          }
        });
        if (viewerCartItemsQuery) {
          // Write the updated query
          client.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug
            },
            data: {
              ...viewerCartItemsQuery,
              viewerCartItemsByShopSlug: {
                ...viewerCartItemsQuery.viewerCartItemsByShopSlug!,
                nodes: viewerCartItemsQuery.viewerCartItemsByShopSlug!.nodes!.filter(
                  (ci) => ci.id !== subscriptionData.data!.itemRemovedFromCart.id
                )
              }
            }
          });
        }
      }
    }
  });
  useCartItemUpdatedByCartSubscription({
    variables: {
      cartId: cartId!
    },
    skip: !cartId || skip,
    shouldResubscribe: true,
    onSubscriptionData({ client, subscriptionData }) {
      if (subscriptionData.data?.cartItemUpdatedByCart) {
        // Update the quantity of the cart item
        client.cache.modify({
          id: subscriptionData.data.cartItemUpdatedByCart.id,
          fields: {
            quantity() {
              return subscriptionData.data!.cartItemUpdatedByCart.quantity;
            }
          }
        });
      }
    }
  });
}

export function useaddVariantToCartPOS() {
  const client = useApolloClient();

  return useRelayMutation<AddVariantToCartPosMutation, AddVariantToCartPosMutationVariables, 'addVariantToCartPOS'>(
    AddVariantToCartPosDocument,
    'addVariantToCartPOS',
    {
      optimisticResponse({ input }) {
        const variant = client.readFragment<VariantDetailsFragment>({
          id: input.variantId,
          fragmentName: 'VariantDetails',
          fragment: VariantDetailsFragmentDoc
        });

        const optimisticId = toGidString('CartItem', `optimistic-${uuid()}`);

        return {
          __typename: 'Mutation',
          addVariantToCartPOS: {
            __typename: 'AddVariantToCartPOSPayload',
            variant: variant!,
            cartItem: {
              __typename: 'CartItem',
              id: optimisticId,
              cartId: '',
              variant: {
                ...variant!,
                quantity: {
                  ...variant!?.quantity,
                  available: variant!?.quantity?.available - input.quantity,
                  reserved: variant!?.quantity?.reserved + input.quantity
                },
                product: {
                  ...variant!?.product,
                  quantity: {
                    ...variant!?.product?.quantity,
                    available: variant!?.product?.quantity?.available - input.quantity,
                    reserved: variant!?.product?.quantity.reserved + input.quantity
                  }
                },
                inventoryItem: {
                  ...variant!?.inventoryItem!,
                  quantity: {
                    ...variant!?.inventoryItem!?.quantity,
                    available: variant!?.inventoryItem!?.quantity?.available - input.quantity,
                    reserved: variant!?.inventoryItem!?.quantity?.reserved + input.quantity
                  }
                },
                customizations: {
                  nodes: []
                }
              },
              variantId: input?.variantId,
              quantity: input?.quantity,
              expiresAt: addHours(new Date(), 5).toUTCString(),
              customizations: {
                nodes: []
              }
            },
            errors: null
          }
        };
      },
      update(cache, { data }) {
        if (data?.addVariantToCartPOS?.cartItem) {
          const shopCartItemsQuery = cache.readQuery<
            ViewerCartItemsByShopSlugQuery,
            ViewerCartItemsByShopSlugQueryVariables
          >({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug: data.addVariantToCartPOS.cartItem.variant.product.shop.slug
            }
          });
          if (
            shopCartItemsQuery?.viewerCartItemsByShopSlug?.nodes?.find(
              (ci) => ci.id === data?.addVariantToCartPOS.cartItem?.id
            )
          ) {
            // The item is already in the cart
            return;
          }
          if (shopCartItemsQuery?.viewerCartItemsByShopSlug) {
            cache.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
              query: ViewerCartItemsByShopSlugDocument,
              variables: {
                shopSlug: data.addVariantToCartPOS.cartItem.variant.product.shop.slug
              },
              data: {
                __typename: 'Query',
                viewerCartItemsByShopSlug: {
                  __typename: 'ViewerCartItemsByShopSlugConnection',
                  nodes: [...shopCartItemsQuery.viewerCartItemsByShopSlug.nodes!, data.addVariantToCartPOS.cartItem],
                  pageInfo: {
                    __typename: 'PageInfo',
                    hasNextPage: false,
                    hasPreviousPage: false,
                    startCursor: '',
                    endCursor: ''
                  }
                }
              }
            });
          }
        }
      }
    }
  );
}

export function useAddVariantToCart() {
  const client = useApolloClient();

  return useRelayMutation<AddVariantToCartMutation, AddVariantToCartMutationVariables, 'addVariantToCart'>(
    AddVariantToCartDocument,
    'addVariantToCart',
    {
      optimisticResponse({ input }) {
        const variant = client.readFragment<VariantDetailsFragment>({
          id: input!.variantId,
          fragmentName: 'VariantDetails',
          fragment: VariantDetailsFragmentDoc
        });

        const optimisticId = toGidString('CartItem', `optimistic-${uuid()}`);

        return {
          __typename: 'Mutation',
          addVariantToCart: {
            __typename: 'AddVariantToCartPayload',
            variant: variant!,
            cartItem: {
              __typename: 'CartItem',
              id: optimisticId,
              cartId: '',
              variant: {
                ...variant!,
                quantity: {
                  ...variant!.quantity,
                  available: variant!.quantity!.available - input.quantity,
                  reserved: variant!.quantity!.reserved + input.quantity
                },
                product: {
                  ...variant!.product,
                  quantity: {
                    ...variant!.product.quantity,
                    available: variant!.product!.quantity!.available - input.quantity,
                    reserved: variant!.product!.quantity!.reserved + input.quantity
                  }
                },
                inventoryItem: {
                  ...variant!.inventoryItem!,
                  quantity: {
                    ...variant!.inventoryItem!.quantity,
                    available: variant!.inventoryItem!.quantity!.available - input.quantity,
                    reserved: variant!.inventoryItem!.quantity!.reserved + input.quantity
                  }
                },
                customizations: {
                  nodes: []
                }
              },
              variantId: input!.variantId,
              quantity: input!.quantity,
              expiresAt: addHours(new Date(), 5).toUTCString(),
              customizations: {
                nodes: []
              }
            },
            errors: null
          }
        };
      },
      update(cache, { data }) {
        if (data?.addVariantToCart?.cartItem) {
          const shopCartItemsQuery = cache.readQuery<
            ViewerCartItemsByShopSlugQuery,
            ViewerCartItemsByShopSlugQueryVariables
          >({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug: data.addVariantToCart.cartItem.variant.product.shop.slug
            }
          });
          if (
            shopCartItemsQuery?.viewerCartItemsByShopSlug?.nodes?.find(
              (ci) => ci.id === data?.addVariantToCart.cartItem?.id
            )
          ) {
            // The item is already in the cart
            return;
          }
          if (shopCartItemsQuery?.viewerCartItemsByShopSlug) {
            cache.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
              query: ViewerCartItemsByShopSlugDocument,
              variables: {
                shopSlug: data.addVariantToCart.cartItem.variant.product.shop.slug
              },
              data: {
                __typename: 'Query',
                viewerCartItemsByShopSlug: {
                  __typename: 'ViewerCartItemsByShopSlugConnection',
                  nodes: [...shopCartItemsQuery.viewerCartItemsByShopSlug.nodes!, data.addVariantToCart.cartItem],
                  pageInfo: {
                    __typename: 'PageInfo',
                    hasNextPage: false,
                    hasPreviousPage: false,
                    startCursor: '',
                    endCursor: ''
                  }
                }
              }
            });
          }
        }
      }
    }
  );
}

export function useUpdateCartItem() {
  const client = useApolloClient();

  return useRelayMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables, 'updateCartItem'>(
    UpdateCartItemDocument,
    'updateCartItem',
    {
      optimisticResponse({ input }) {
        const cartItem = client.readFragment<CartItemFragment>({
          id: input.cartItemId,
          fragmentName: 'CartItem',
          fragment: CartItemFragmentDoc
        });

        if (cartItem) {
          let quantityDiff = 0;

          if (cartItem.expiresAt && compareAsc(new Date(cartItem.expiresAt), new Date()) === 1) {
            quantityDiff = input.quantity - cartItem.quantity;
          }

          return {
            __typename: 'Mutation',
            updateCartItem: {
              __typename: 'UpdateCartItemPayload',
              cartItem: {
                ...cartItem,
                quantity: input.quantity,
                variant: {
                  ...cartItem.variant,
                  quantity: {
                    ...cartItem?.variant?.quantity,
                    available: cartItem.variant.quantity.available - quantityDiff,
                    reserved: cartItem.variant.quantity.reserved + quantityDiff
                  },
                  product: {
                    ...cartItem.variant.product,
                    quantity: {
                      ...cartItem.variant.product.quantity,
                      available: cartItem.variant.product.quantity.available - quantityDiff,
                      reserved: cartItem.variant.product.quantity.reserved + quantityDiff
                    }
                  },
                  inventoryItem: {
                    ...cartItem.variant.inventoryItem,
                    quantity: {
                      ...cartItem.variant.inventoryItem!.quantity,
                      available: cartItem.variant.inventoryItem!.quantity.available - quantityDiff,
                      reserved: cartItem.variant.inventoryItem!.quantity.reserved + quantityDiff
                    }
                  }
                }
              } as CartItem,
              errors: null
            }
          };
        }

        return {
          __typename: 'Mutation',
          updateCartItem: {
            __typename: 'UpdateCartItemPayload',
            cartItem: null,
            errors: null
          }
        };
      }
    }
  );
}

export function useRemoveItemFromCart() {
  const client = useApolloClient();

  return useRelayMutation<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables, 'removeItemFromCart'>(
    RemoveItemFromCartDocument,
    'removeItemFromCart',
    {
      optimisticResponse: ({ input }) => {
        const cartItem = client.readFragment<CartItemFragment>({
          id: input.cartItemId,
          fragmentName: 'CartItem',
          fragment: CartItemFragmentDoc
        });

        return {
          __typename: 'Mutation',
          removeItemFromCart: {
            __typename: 'RemoveItemFromCartPayload',
            cartItem: cartItem,
            errors: null
          }
        };
      },
      update(cache, { data }) {
        if (data?.removeItemFromCart?.cartItem) {
          const shopCartItemsQuery = cache.readQuery<
            ViewerCartItemsByShopSlugQuery,
            ViewerCartItemsByShopSlugQueryVariables
          >({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug: data.removeItemFromCart.cartItem.variant.product.shop.slug
            }
          });
          if (shopCartItemsQuery?.viewerCartItemsByShopSlug) {
            cache.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
              query: ViewerCartItemsByShopSlugDocument,
              variables: {
                shopSlug: data.removeItemFromCart.cartItem.variant.product.shop.slug
              },
              data: {
                __typename: 'Query',
                viewerCartItemsByShopSlug: {
                  ...shopCartItemsQuery.viewerCartItemsByShopSlug,
                  nodes: shopCartItemsQuery.viewerCartItemsByShopSlug.nodes?.filter(
                    (node) => node.id !== data.removeItemFromCart.cartItem!.id
                  )
                }
              }
            });
          }
        }
      }
    }
  );
}

export function useRemoveItemFromCartPos() {
  const client = useApolloClient();

  return useRelayMutation<
    RemoveItemFromCartPosMutation,
    RemoveItemFromCartPosMutationVariables,
    'removeItemFromCartPos'
  >(RemoveItemFromCartPosDocument, 'removeItemFromCartPos', {
    optimisticResponse: ({ input }) => {
      const cartItem = client.readFragment<CartItemFragment>({
        id: input.cartItemId,
        fragmentName: 'CartItem',
        fragment: CartItemFragmentDoc
      });

      return {
        __typename: 'Mutation',
        removeItemFromCartPos: {
          __typename: 'RemoveItemFromCartPosPayload',
          cartItem: cartItem,
          errors: null
        }
      };
    },
    update(cache, { data }) {
      if (data?.removeItemFromCartPos?.cartItem) {
        const shopCartItemsQuery = cache.readQuery<
          ViewerCartItemsByShopSlugQuery,
          ViewerCartItemsByShopSlugQueryVariables
        >({
          query: ViewerCartItemsByShopSlugDocument,
          variables: {
            shopSlug: data.removeItemFromCartPos.cartItem.variant.product.shop.slug
          }
        });
        if (shopCartItemsQuery?.viewerCartItemsByShopSlug) {
          cache.writeQuery<ViewerCartItemsByShopSlugQuery, ViewerCartItemsByShopSlugQueryVariables>({
            query: ViewerCartItemsByShopSlugDocument,
            variables: {
              shopSlug: data.removeItemFromCartPos.cartItem.variant.product.shop.slug
            },
            data: {
              __typename: 'Query',
              viewerCartItemsByShopSlug: {
                ...shopCartItemsQuery.viewerCartItemsByShopSlug,
                nodes: shopCartItemsQuery.viewerCartItemsByShopSlug.nodes?.filter(
                  (node) => node.id !== data.removeItemFromCartPos.cartItem!.id
                )
              }
            }
          });
        }
      }
    }
  });
}

export function useUpdateCartItemPos() {
  const client = useApolloClient();

  return useRelayMutation<UpdateCartItemPosMutation, UpdateCartItemPosMutationVariables, 'updateCartItemPos'>(
    UpdateCartItemPosDocument,
    'updateCartItemPos',
    {
      optimisticResponse: ({ input }) => {
        const cartItem = client.readFragment<CartItemFragment>({
          id: input.cartItemId,
          fragmentName: 'CartItem',
          fragment: CartItemFragmentDoc
        });

        if (cartItem) {
          let quantityDiff = 0;

          if (cartItem.expiresAt && compareAsc(new Date(cartItem.expiresAt), new Date()) === 1) {
            quantityDiff = input.quantity - cartItem.quantity;
          }

          return {
            __typename: 'Mutation',
            updateCartItemPos: {
              __typename: 'UpdateCartItemPosPayload',
              cartItem: {
                ...cartItem,
                quantity: input.quantity,
                variant: {
                  ...cartItem.variant,
                  quantity: {
                    ...cartItem.variant?.quantity,
                    available: cartItem.variant.quantity?.available - quantityDiff,
                    reserved: cartItem.variant.quantity?.reserved + quantityDiff
                  },
                  product: {
                    ...cartItem.variant.product,
                    quantity: {
                      ...cartItem.variant.product.quantity,
                      available: cartItem.variant.product.quantity?.available - quantityDiff,
                      reserved: cartItem.variant.product.quantity?.reserved + quantityDiff
                    }
                  },
                  inventoryItem: {
                    ...cartItem.variant.inventoryItem,
                    quantity: {
                      ...cartItem.variant.inventoryItem!.quantity,
                      available: cartItem.variant.inventoryItem!.quantity?.available - quantityDiff,
                      reserved: cartItem.variant.inventoryItem!.quantity?.reserved + quantityDiff
                    }
                  }
                }
              } as CartItem,
              errors: null
            }
          };
        }

        return {
          __typename: 'Mutation',
          updateCartItemPos: {
            __typename: 'UpdateCartItemPosPayload',
            cartItem: null,
            errors: null
          }
        };
      }
    }
  );
}

export function useAddCartItemCustomization() {
  return useRelayMutation<
    AddCartItemCustomizationMutation,
    AddCartItemCustomizationMutationVariables,
    'addCartItemCustomization'
  >(AddCartItemCustomizationDocument, 'addCartItemCustomization', {
    update(client, { data }) {
      if (data?.addCartItemCustomization.cartItemCustomization) {
        client.modify({
          id: data.addCartItemCustomization.cartItemCustomization.cartItemId,
          fields: {
            customizations(prev) {
              return {
                ...prev,
                nodes: [...prev.nodes, data.addCartItemCustomization.cartItemCustomization]
              };
            }
          }
        });
      }
    }
  });
}

export function useUpdateCartItemCustomization() {
  return useRelayMutation<
    UpdateCartItemCustomizationMutation,
    UpdateCartItemCustomizationMutationVariables,
    'updateCartItemCustomization'
  >(UpdateCartItemCustomizationDocument, 'updateCartItemCustomization', {
    update(client, { data }) {
      if (data?.updateCartItemCustomization.cartItemCustomization) {
        const customization = data.updateCartItemCustomization.cartItemCustomization;
        client.modify({
          id: data.updateCartItemCustomization.cartItemCustomization.cartItemId,
          fields: {
            customizations(prev) {
              return {
                ...prev,
                nodes: prev.nodes.map((c: CartItemCustomizationFragment) =>
                  c.customizationOptionId === customization.customizationOptionId && c.index === customization.index
                    ? customization
                    : c
                )
              };
            }
          }
        });
      }
    }
  });
}
