import { styled } from '@mui/material/styles';
import MUIRadio, { RadioProps } from '@mui/material/Radio';
import React, { FC } from 'react';
import { colors } from '../../theme/colors';

const Radio: FC<RadioProps> = ({ ...props }) => {
  return <StyledRadio {...props}/>;
};

export default Radio;

const StyledRadio = styled(MUIRadio)({
  color: colors.gray300,
  height: '20px',
  width: '20px',
  marginTop: '4px',
  marginRight: '8px',
  '&.Mui-checked': {
    color: colors.purple500
  }
});
